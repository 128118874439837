<template>
    <div class="box">
        <div class="theme-search-card-container">
            <ThemeCard v-for="group in groups" :key="`group_${group.id}`" :group="group" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ThemeCard from '../../components/Search/ThemeCard.vue'

    export default {
        title: 'searchform.searchbytheme',
        name: 'ThemeSearch',
        components: {
            ThemeCard
        },
        computed: {
            ...mapState('group', ['groups'])
        },
        methods: {
            ...mapActions('group', ['getAll'])
        },
        created() {
            this.getAll()
        }
    }
</script>

<style scoped>
    .theme-search-card-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        grid-gap: 1rem;
    }
</style>
